@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;

  height: 100%;
  background: linear-gradient(
      to bottom,
      #f9f5f4 0%,
      #f9f5f4 170px,
      #ffff 170px,
      #ffff 100%
    )
    no-repeat !important;
}

.outerSelect{
  width:90px;
  font: normal normal 400 12px/20px Poppins;

}

.optionSelect_menu{
margin-top: 0px !important;
border-top: 0px !important;
padding-top: 0px;
}
a {
  color: unset !important;
  text-decoration: none !important;
}

.row-width{
  width: 1124px;
}

.btn-purple {
  background: #a4a2dc 0% 0% no-repeat padding-box !important;
   
  border-radius: 27px !important;
  border: #a4a2dc !important;
  /* width: 260px; */
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: 40px;
  color: #ffffff !important;
  text-transform: uppercase;
  font: normal normal 600 12px/21px Poppins !important;
}
.btn-darkpurple {
  background: #6277bb 0% 0% no-repeat padding-box !important;
   
  border-radius: 27px !important;
  border: #6277bb !important;
  /* width: 260px; */
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: 40px;
  color: #ffffff !important;
  text-transform: uppercase;
  font: normal normal 600 12px/21px Poppins !important;
}
.btn-border {
  /* box-shadow: 0px 3px 26px #00000017 !important; */
  border: 1px solid #a4a2dc !important;
  border-radius: 27px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: 40px;
  background: #ffff !important;
  color: #a4a2dc !important;
  text-transform: uppercase;
  font: normal normal 400 12px/21px Poppins !important;
}

button.sidebar-toggle {
  background-color: #f7f4f2 !important;
}
.general-title{
  margin-left: 260px !important;
  padding-left: 0;
}
.general-title.container {
  padding-left: 0;
}
.general-title-adjust{
  padding-left: 0!important;
  margin-left: 0 !important;
}

.general-title h1,.general-title-adjust h1  {
  font: normal normal 700 20px/60px Poppins;
  color: #000000;
  margin-bottom: 0;
  padding-top: 30px;
  line-height: 30px;
}

.general-title label,.general-title-adjust label {
  font: normal normal normal 12px/60px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.45;
  line-height: inherit;
  padding-bottom: 20px;
}

.svg-margin {
  margin-left: 7rem;
}

hr {
  color: #dddddd !important;
  margin: 0.5rem 0 !important;
}
hr:not([size]) {
  height: 2.5px;
}
.redTitle {
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #ef1616;
}

.purpleText {
  text-decoration: underline;
  font: normal normal normal 12px/25px Poppins;
  color: #a4a2dc;

}

.content {
  font: normal normal normal 12px/25px Poppins;
  color: #000000;
  vertical-align: -webkit-baseline-middle;
  line-height: 15px;
}

.content-midsize {
  font: normal normal 300 12px/16px Poppins;
  line-height: 15px;
}
.content-smallsize {
  font: normal normal 300 12px/16px Poppins;
  line-height: 15px;
}

.dashboard {
  font: normal normal 400 12px/21px Poppins;
}
input[type='date']::-webkit-calendar-picker-indicator {
  background: url(./assets/images/calendar.svg) no-repeat;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: url(./assets/images/clock.svg) no-repeat;
}
.btn-close {
  background: transparent url(./assets/images/close.svg) center/1em auto
    no-repeat !important;
}

.backLink {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #a4a2dc !important;
  text-transform: uppercase;
  text-decoration: none;
}

label.form-check-label {
  padding-left: 10px;
  font-size: 14px;
  /* padding-top: 5px; */
 

}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
  text-transform: uppercase;
  color: #AFAFAF;
  font-size: 12px;
}

.form-select {

  font: normal normal 400 16px/20px Poppins !important;
  color: #AFAFAF !important;
    border: 1px solid #E7E6E9 !important;
  border-radius: 5px 5px 0px 0px !important;

  font: normal normal 400 12px/20px Poppins !important;
  color: #afafaf !important;
  border: 1px solid #e7e6e9 !important;


  opacity: 1 !important;
  background-size: 13px 13px !important;
  background-image: url(./assets/images/chevron.svg) !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:focus {
  box-shadow: none;
}

input.form-control,
select {
  height: 40px !important;
  border: 1px solid #e7e6e9 !important;
}

.titleSection,
.accordion-button,
.accordion-button:not(.collapsed) {
  font: normal normal 600 22px/60px Poppins !important;
  color: #000000 !important;
}
.accordion-button::after {
  background-image: url(./assets/images/plus.svg) !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url(./assets/images/minus.svg) !important;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: transparent !important;
  box-shadow: none !important;
}
.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.subTitle {
  font: normal normal 600 10px/20px Poppins;
  letter-spacing: 0px;
  color: #b8998d;
  text-transform: uppercase;
}

.subTitle-t,
.tableTitle {
  font: normal normal 500 10px/20px Poppins;
  color: #b8998d;
  text-transform: uppercase;
}

.text-t,
.tableContent {
  font: normal normal 500 12px/23px Poppins;
  color: #000000;
  text-transform: capitalize;
  line-height: 15px;
}
.tableContentBg {
  background: rgba(212, 189, 180, 0.15);
  line-height: 15px;
}
.tableContentBg .btn-darkpurple {
  width: 100% !important;
  height: auto !important;
  font: normal normal 700 12px/21px Poppins !important;
  line-height: 15px;
}

.addPayment .tableContentBg,
.addRefund .tableContentBg,
.addNewDesigner .tableContentBg {
  margin-inline: -24px !important;
}

/* .Table > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-top: var(--bs-gutter-y);
}

.Table {
  margin-top: 41px !important;
  margin-bottom: 50px !important;
} */

.lastC {
  padding-right: 102px !important;
}

.white-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border-radius: 5px;
  margin-left: 260px !important;
}
.white-container-adjust{
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border-radius: 5px;
  margin-left: 0px !important;
}
.manageOrders{
  margin-left: 260px;
}

.descTitle {
  font: normal normal 700 14px/60px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.Deletebutton-table {
  padding-left: 10px;
}
::placeholder {
  font: normal normal 500 12px/25px Poppins !important;
  letter-spacing: 0px;
  color: #afafaf !important;
}
.searchInput::placeholder {
  padding-left: 10px;
  color: #153288 !important;
  opacity: 0.38;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.searchInput {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eff1fa;
  border-radius: 35px;
  width: 260px;
  height: 40px;
}

.paginationSelect {
  width: 100px !important;
}

.form-check-input:checked[type='radio'] {
  background-image: url(./assets/images/radio.svg) !important;
  background-color: #ffffff !important;
  border-color: #a4a2dc !important;
}

input[type='checkbox'] {
  width: 15px;
  height: 15px;
  border: 1px solid #ededed;
  border-radius: 4px;
}
.form-check-input:checked[type='checkbox'] {
  background-image: url(./assets/images/RectangleCheckbox.svg) !important;
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  background-size: 9px;
}

.form-check-input:checked {
  background-color: #a4a2dc !important;
  border-color: #a4a2dc !important;
}
.searchInput {
  background: url(./assets/images/search.svg) no-repeat 93%;
  background-size: 20px;
  padding: 20px;
}

.searchInput::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  -webkit-appearance: none;
  -webkit-user-modify: read-write !important;
  /* content: url(./assets/images/search.svg) !important;    */
  content: none !important;
}
.itemName {
  font: normal normal 700 12px/23px Poppins;
  color: #a4a2dc;
  text-transform: uppercase;
  line-height: 15px;
}

.grey-border {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.grey-border .tableNewAppBg {
  background: linear-gradient(
    to right,
    #cecdf2 0%,
    #cecdf2 2%,
    #d4bdb426 2%,
    #d4bdb426 100%
  );
}
.grey-border .tableRetryBg {
  background: linear-gradient(
    to right,
    #f7e4f3 0%,
    #f7e4f3 2%,
    #d4bdb426 2%,
    #d4bdb426 100%
  );
}
.grey-border .tablePickupsBg {
  background: linear-gradient(
    to right,
    #d4e8d7 0%,
    #d4e8d7 2%,
    #d4bdb426 2%,
    #d4bdb426 100%
  );
}
.grey-border .tableUnconfBg {
  background: linear-gradient(
    to right,
    #d3d3d3 0%,
    #d3d3d3 2%,
    #d4bdb426 2%,
    #d4bdb426 100%
  );
}

.dashboard-title {
  font: normal normal 800 14px/27px Poppins;
  color: #000000;
}

/* Keep the media queries at the end */

@media (min-width: 1400px)
{
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1100px !important;
  }
}

@media only screen and (max-width: 600px) {
  .searchInput {
    width: 100%;
  }

  .paginationSelect {
    margin-top: 15px;
  }
}

.purple-background-container {
  background: rgba(164, 162, 220, 0.17);
  border-radius: 5px;
  margin-left: 260px !important;
}
.purple-background-container-adjust {
  background: rgba(164, 162, 220, 0.17);
  border-radius: 5px;
}

.header-2 {
  text-align: left;
  font: normal normal 600 12px/60px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  line-height: 45px;
}


.circle-newApp {
  height: 15px;
  width: 15px;
  background-color: #cecdf2;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}
.circle-pickups {
  height: 18px;
  width: 18px;
  background-color: #f7e4f3;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}
.circle-retryApp {
  height: 18px;
  width: 18px;
  background-color: #d4e8d7;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}
.circle-unconf {
  height: 18px;
  width: 18px;
  background-color: #d3d3d3;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}

.circle-rose {
  height: 14px;
  width: 14px;
  background-color: #ffa9a9;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}

.circle-green {
  height: 14px;
  width: 14px;
  background-color: #dceec2;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}

.circle-yellow {
  height: 14px;
  width: 14px;
  background-color: #f1f1a0;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}

.circle-beige {
  height: 14px;
  width: 14px;
  background-color: #f6d59f;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}

.Bride-name-color {
  color: #a4a2dc;
  text-transform: uppercase;
  opacity: 1;
}

.lexique-component {
  align-items: center;
}

.circle-description {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  padding-left: 18px !important;

  font: normal normal normal 12px/23px Poppins;
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 1;
}

.circleConteny {
  padding: inherit !important;
}
.left-image-button-pagination {
  transform: rotate(90deg);

  opacity: 1;
}

.left-button-pagination {
  background-color: rgba(212, 189, 180, 0.1) !important;

  border-radius: 45px 0px 0px 45px !important;
  padding: 50px;
  height: 55px;
  width: 31px;
  border: none !important;
}

.page-button {
  text-align: center;
  margin-top: 26px;
  margin-bottom: 36px;
}

.button-pagination {
  background-color: rgba(212, 189, 180, 0.1) !important;
  border: none !important;
  border-radius: 0px 0px 0px 0px !important;
  padding: 50px;
  height: 55px;
  font: normal normal medium 14px/27px Poppins;
  width: 31px;
  color: #707070 !important;
}

.current-page {
  color: #a4a2dc !important;
}

.right-image-button-pagination {
  transform: rotate(270deg);

  opacity: 1;
}
.right-button-pagination {
  background-color: rgba(212, 189, 180, 0.1) !important;

  border-radius: 0px 45px 45px 0px !important;
  padding: 50px;
  height: 55px;
  width: 31px;
  border: none !important;
}

.header-3 {
  height: 31px;
  text-align: left;
  font: normal normal 600 16px/60px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.red-message-border {
  text-align: center;
  font: normal normal 600 12px/21px Poppins;
  letter-spacing: 0px;
  color: #cd1d1d;
  text-transform: uppercase;
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #cd1d1d;
  border-radius: 27px;
  opacity: 1;
}

.y-axis {
  font: normal normal bold 12px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.y-axis-title {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.arrow_box {
  width: 146px;
  text-align: center;
  height: 36px;
  padding-top: 5px;
  border: 1px solid #dddddd;
  border-radius: 45px 45px 45px 45px !important;
}

.apexcharts-tooltip {
  border-radius: 45px 45px 45px 45px !important;
  box-shadow: 0px 1px 8px #53559b2d;
  border-radius: 18px;
}

.chart-title {
  align-items: baseline;
  font: normal normal bold 14px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.chart-title-purple {
  font: normal normal bold 14px/25px Poppins;
  letter-spacing: 0px;
  color: #a4a2dc;
}

.button-pagination-graph {
  background-color: rgba(212, 189, 180, 0.1) !important;
  border: none !important;
  border-radius: 0px 0px 0px 0px !important;
  padding: 50px;
  height: 55px;
  font: normal normal 300 11px/25px Poppins !important;
  width: 80px;
  color: #1a1a1a !important;
}
.chart-border {
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.Analytics-subtitle {
  font: normal normal 600 12px/60px Poppins;
}

.analytics-purpul-subtitle {
  font: normal normal 600 12px/21px Poppins;
  letter-spacing: 0px;
  color: #a4a2dc;
  text-transform: uppercase;
  opacity: 1;
}

.apexcharts-bar-area:hover {
  fill: rgba(98, 119, 187, 1) !important;
}

.Analytics-dropdown {
  height: 40px !important;
}

.map {
  width: 530px !important;
  height: 567px !important;
}


:root {
  --background-gradient: linear-gradient(30deg, #f39c12 30%, #f1c40f);
  --gray: #34495e;
  --darkgray: #2c3e50;
}

select {
  /* Reset Select */
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  color: #fff;
  background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: .25em;
  overflow: hidden;
}
/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: .25s all ease;
  pointer-events: none;
}
/* Transition */
.select:hover::after {
  color: #f39c12;
}

.react-tel-input .form-control {
  width: 100% !important;
}


