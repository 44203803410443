$glass: rgba(255, 255, 255, 0.2);
$glass-icon: rgba(255, 255, 255, 0.3);
$gradient: linear-gradient(35deg, red, purple);
$option: white;

body {
  background: $gradient;
}

/* <select> styles */
select {
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 20em;
  height: 3em;
  padding: 0 4em 0 1em;

  border-radius: 0.25em;
  box-shadow: none !important;
  cursor: pointer;
  /* <option> colors */
  option {
    color: inherit;
    background-color: $option;
    border: 1px solid #E7E6E9 !important;
    box-shadow: none !important;
  }
  /* Remove focus outline */
  &:focus {
    border: 1px solid #E7E6E9 !important;
    outline: none;
    box-shadow: none !important;
  }
  /* Remove IE arrow */
  &::-ms-expand {
    border: 1px solid #E7E6E9 !important ;
    display: none;
    box-shadow: none !important;
  }
}

